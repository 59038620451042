import React, { Component } from "react";
import { Container, Image, Button } from "semantic-ui-react";
import LayoutInner from "../../components/layout/layout-inner";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import SecondaryHeading from "../../components/secondary-heading/secondary-heading";
import SEO from "../../components/seo/seo";

import style from "./style.module.css";
import { INFO_EMAIL, SUPPORT_PHONE } from "../../constants";

class ZoomLessons extends Component {

  // Temporarily redirect to home page while zoom lessons are not being offered
  componentDidMount() {
    window.location.replace("https://www.rocksteadymusicschool.com");
  }

  copy = () => (
    <div>
      <p>
        Rocksteady Zoom Lessons are a great way to continue your child’s musical
        journey whilst the world is finding its feet again. Whilst we’re still
        not able to teach in most schools at the moment, our Band Leaders are
        back and ready to deliver fun packed lessons throughout the summer.
      </p>
      <p>
        What’s more, there are four styles of lesson to choose from so that you
        can be sure there’s something to suit your child’s needs.
      </p>
      <SecondaryHeading heading="Lesson styles" />
      <p>
        <strong style={{ color: "#0072ce" }}>Band lessons:</strong>
        <br />
        Just like rehearsals in school, experience the comradery of learning in
        a band with up to five other children in a fun and fully interactive
        online session.
      </p>
      <p>
        <strong style={{ color: "#0072ce" }}>
          Instrumental group lessons:
        </strong>
        <br />
        Prefer to learn new skills on your instrument? Join a group of other
        children learning guitar, bass, drums, keyboard or vocals for focused
        sessions designed to up your game.
      </p>
      <p>
        <strong style={{ color: "#0072ce" }}>Group singing and songmaking lessons:</strong>
        <br />
        No instrument? No problem. This session is for children to have fun singing, 
        creating music, enjoying musical games and more together.
      </p>
      <p>
        <strong style={{ color: "#0072ce" }}>121 lessons:</strong>
        <br />
        For those who want maximum focus on learning their instrument and
        personalised progression tailored to your needs.
      </p>

      <SecondaryHeading heading="Free Trial Zoom Lessons" />

      <p>
        We want as many children as possible to experience the social and wellbeing 
        benefits of playing music so, for a limited time, you can now book <strong>
          a free trial Zoom Lesson for your child.</strong>
      </p>
      <p className={style.bookingtitle}>
        <strong style={{ color: "#0072ce" }}>How to book</strong>
      </p>
      <ul className={style.bookinglist}>
        <li>
          Select the type of lesson, look out for the lessons marked ‘free trial’ 
          and select the one you’d like. No payment details are needed, and you’ll 
          receive an email confirming the booking
        </li>
        <li>After the lesson, you’ll receive an invite to sign up to your remaining 
          lessons – so make sure that if your child loved it that you sign up for 
          the rest of the sessions, as bookings are taking on a first-come, 
          first-served basis.
        </li>
      </ul>
      <p>
        <strong style={{ color: "#0072ce" }}>Not your scene? No problem</strong>
        <br />
        We're confident your child will love Zoom Lessons. If they don’t, we can switch to a 
        different lesson type or better time slot, depending on availability, or offer a full 
        refund.
      </p>

      <div className={style.center}>
        <Button
          primary
          href="#schedule"
          className={style.scheduleButton}
          compact
          target=""
          rel="noopener noreferrer"
        >
          Book Your Space Today
        </Button>
      </div>

      <SecondaryHeading heading="Further Lesson Information" />
      <p>
        Sessions are 30 minutes long and delivered by a Rocksteady Band Leader with access to 
        Rocksteady At Home content for extra play between lessons building their confidence and 
        musical skills. 121 lessons are available for an additional £66.63 per month and all 
        group lessons are an additional £21.88 for existing subscribers. 
      </p>
      <p>
        To make it even easier for your child to play between lessons we have teamed up with 
        Normans Music to provide exclusive instrument bundles which you can select when booking. 
      </p>
      <p>  
        Lessons are available throughout June and July and will be allocated on a first come 
        first serve basis. We are going to be increasing capacity over the rest of the summer so 
        please don’t worry if there’s not a place available right now, your child will be able 
        to get started soon.
      </p>

      <SecondaryHeading heading="What Other Parents Say" />
      <div className="quotes" style={{ textAlign: "center" }}>
        <p>
          <strong style={{ color: "#0072ce" }}>
            “The lessons were really good and from the first lesson he was
            really encouraged and motivated. My son then practised four times
            throughout the week between two lessons, he learnt how to navigate
            around Rocksteady at Home and has mastered parts by himself.”
          </strong>{" "}
          <em>Camille, Bedfordshire</em>
        </p>
        <p>
          <strong style={{ color: "#0072ce" }}>
            “The lesson went really well. There was great rapport with the Band
            Leader.”
          </strong>{" "}
          <em>Rachel, Suffolk</em>
        </p>
        <p>
          <strong style={{ color: "#0072ce" }}>
            “My daughter absolutely loved the session. She came off saying it
            was THE BEST zoom class ever!”
          </strong>{" "}
          <em>Helen, Northamptonshire</em>
        </p>
      </div>

      <SecondaryHeading heading="Lesson Schedule" />
    </div>
  );

  psInfo = () => (
    <p>
      If you have any questions or need help with the booking system please email
      {" "}
      <a href={`mailto:${INFO_EMAIL}`}>
        {INFO_EMAIL}
      </a>{" "}
      or call {SUPPORT_PHONE}. At times of high demand it may take us longer to
      respond than we would like. For any questions or changes to your existing
      subscription, just login to{" "}
      <a href="https://backstage.rocksteadymusicschool.com">
        Backstage, your Online Portal
      </a>
      . We’ll continue to do our best for you and thank you for your continued
      support throughout this time.
    </p>
  );

  pageContent = () => (
    <LayoutInner menuEnabled={false}>
      <SEO title="Zoom Lessons" keywords={[`rocksteady`]} />
      <Container>
        <SecondaryNavBar
          product="generic"
          title="Zoom Lessons"
          titlePath="/zoom-lessons/"
          titleActive={true}
          links={[]}
        />
        <div className={style.section}>
          <Image
            src="/images/online-lessons/zoom-lessons-banner.png"
            className={style.banner}
          />
          <div className={style.content}>
            {this.copy()}
            <Button className={style.anchor} id="schedule" aria-hidden="true" />
            <iframe
              className={style.iframe}
              title="Rocksteady Zoom Lessons"
              src="https://rocksteady-music-school.class4kids.co.uk/"
            ></iframe>
            <br />
            <br />
            <SecondaryHeading heading="Contact Us" />
            {this.psInfo()}
          </div>
        </div>
      </Container>
    </LayoutInner>
  );

  render() {
    return this.pageContent();
  }
}

export default ZoomLessons;
